/* Modal styles */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    background-color: #263238;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    min-width: 300px;
    width: fit-content;
    margin: 5% auto;
    color: #eceff1;
  }
  
  .close {
    width: 100%;
    color: #eceff1;
    font-size: 24px;
    cursor: pointer;
    text-align: right;
}
  
  .close:hover {
    color: #2196f3;
  }
  
  .callInfoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .controls {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  .acceptControl, .declineControl {
    font-size: 30px;
    margin: 20px 25px;
    padding: 5px;
    width: 50px;
    border-radius: 10px;
    background-color: green;
  }

  .declineControl {
    background-color: red;
  }

  .acceptControl:hover, .declineControl:hover {
    opacity: .8;
    cursor: pointer;
  }
  