.header {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
}

.header h1 {
    width: 100%;
}

.header h1 {
    display: flex;
    padding: 20px;

}
.connectionsContainer {
    width: 100%;
    height: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #141b3132;
}
.options {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #141b31;
}

.option, .active {
    width: 50%;
    padding: 5px;
    height: 100%;
    text-align: center;
    height: fit-content;
}

.option:hover {
    background-color: var(--mantine-color-myColors-9);
    cursor: pointer;
}
.active {
 
    background-color: #4661c2;
    transition: ease-in .4s;
    cursor: pointer;

}

.connection {
    padding: 0;
    display: grid;
    grid-template-columns: 16% 16% 16% 16% 16% 16%;
    width: 100%;
    margin: 10px;
    align-items: center;
    height: fit-content;    
}
.connectionItem {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}
.connectionItem a {
    margin: 0;
}
.profilePicture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0px !important;
}
.control {
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.control:hover {
    font-size: 30px;
    cursor: pointer;
    transition: ease .4s;
    color:var(--mantine-color-myColors-9);
}

.user {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #141b31;
    height: fit-content;
    width: 300px;
    padding: 10px;
    justify-content: first-baseline;
    border-radius: 15px;
    margin: auto;
    margin-top: 0;
    max-height: 500px;
}



.username {
    font-size: 26px;
    width: 100%;
    text-align: center;
    width: fit-content;
}

.controlsContainer {
    display: flex;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-around;
    padding: 20px;
}


[data-active="true"] {
background-color: var(--mantine-color-myColors-9);
border-radius: 5px;
}

.tab {
    background-color: transparent !important;
}

@media screen and (max-width: 768px) {

    
    .modalContainer {
        flex-direction: column !important;
        width: 350px;
    }

    .mediaContainer {
        width: 100% !important;
    }

    .tableContainer {
        width: 100% !important;
    }

}