.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.header h1 {
    width: 100%;
}

.searchContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    height: fit-content;

}

.searchContainer .searchIcon {
    margin-right: -100px;
    width: 30px;
    height: 30px;

}

.sortContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 70%;
    align-items: center;
    font-size: 18px;
}


.tableContainer {
    list-style: none;
    margin: 0;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tableHead,
.tableRow {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    justify-content: space-evenly;
}

.tableHead {
    background-color: #1b2545;
}
.sortersHolder {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.sortersHolder .active {
    color: #4661c2;
}

.sortersHolder svg:hover {
    color: #4661c2;
    cursor: pointer;
}
.tableCell,
.tableCellAction {
    display: flex;
}


.tableCellAction {
    text-align: center;

}

.tableCellAction:hover {
    color: #4661c2;
    cursor: pointer;
}

.errMsg {
     width:  75%;
     background-color: red;
     color: white;
     padding: 5px;
}

