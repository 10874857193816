.loaderOutline {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    border-radius: 20px;
   border: 1px solid #dee1e4;
   margin: 0;
}


.loaderProgress {
    background-color: #dee1e4;
    height: 100%;
    border-radius: 20px;
    animation-name: loader;
    animation-duration: 1s;
}
@keyframes loader {
    0% {width: 0px;}
    100% {width: 100%; display: none;}
}

