.wrapper {
    display: flex;
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-8));
    border-radius: var(--mantine-radius-lg);
    padding: rem(4px);
    border: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-8));
  
   
  }
  
  .form {
    flex: 1;
    padding: var(--mantine-spacing-xl);
    padding-left: calc(var(--mantine-spacing-xl) * 2);
    border-left: 0;
  
    @media (max-width: 768px) {
      padding: var(--mantine-spacing-md);
      padding-left: var(--mantine-spacing-md);
    }
  }
  
  .fields {
    margin-top: rem(-12px);
  }
  
  .fieldInput {
    flex: 1;
  
    & + & {
      margin-left: var(--mantine-spacing-md);
  
      @media (max-width: 768px) {
        margin-left: 0;
        margin-top: var(--mantine-spacing-md);
      }
    }
  }
  
  .fieldsGroup {
    display: flex;
  
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .contacts {
    box-sizing: border-box;
    position: relative;
    border-radius: var(--mantine-radius-lg);
    background-size: cover;
    background-position: center;
    border: rem(1px) solid transparent;
    padding: var(--mantine-spacing-xl);
    flex: 0 0 rem(280px);
  
    @media (max-width: 768px) {
      margin-bottom: var(--mantine-spacing-sm);
      padding-left: var(--mantine-spacing-md);
    }
  }
  
  .title {
    margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
  
    @media (max-width: 768px) {
      margin-bottom: var(--mantine-spacing-xl);
    }
  }
  
  .control {
    @media (max-width: 768px) {
      flex: 1;
    }
  }

  @media screen and  (max-width: 768px) {
   
   
   .wrapper {
   
    flex-direction: column !important;
    
  }
  }