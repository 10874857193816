.wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  
  .dropzone {
    padding-bottom: rem(50px) !important;
  }
  
  
  
  .control {
    width: 50%;
    align-self: center;
}