.icon {
    margin-top: 10px;
    width: 32px;
    height: 32px;
    align-self: flex-end;
    border: 1px solid white;
    border-radius: 50%;
    padding: 2px;
}

.icon:hover {
    color: var(--mantine-color-myColors-6);
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.042);
    border-color: var(--mantine-color-myColors-6);
}