
.header {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    height: fit-content;
    border-bottom: 2px solid #dee1e40c;
    justify-content: space-between;
    height: 3em;
    background-color: #1b2545;
}

.imageContainer {
    width: 30%;
}
.header img {
    margin-top: 0;
    height: 3em;
    width: fit-content;
}
.imageHolder img {
    height: 3em;
    width: fit-content;
}

.navContainer {
    width:70%;
    justify-content: flex-end;
    display: flex;
}

.navItem {
    margin: 0;
    padding: 0;
    display: flex;
    width: max-content;
    min-width: 120px;
    max-width: 120px;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-decoration: none;
    letter-spacing: 1px;
    border-color: transparent;
    box-shadow: inset 0 0 0 0 var(--mantine-color-indigo-light);
    transition: all ease .5s;
}

.navItem:hover {
    height: 100%;
    box-shadow: inset 120px 0 0 0 var(--mantine-color-indigo-light);
    cursor: pointer;
  
}

