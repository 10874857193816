.header {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
}

.header h1 {
    width: 100%;
}

.header h1 {
    display: flex;
    padding: 20px;

}


.profile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.profile video {
    width: 600px;
    aspect-ratio: 16/9;
}

.editControl {
    font-size: 20px !important;
    text-decoration: none !important;
    color: white;
}
.editControl:hover {
    color: #4661c2;
    cursor: pointer;
}

.overlay {
    position: fixed;
    background-color: rebeccapurple;
}
.container {
    position: relative;
    width: 100%;
  }
.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 10%;
    left: 90%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .container:hover .image {
    opacity: 0.3;
  }
  
  .container:hover .middle {
    opacity: 1;
  }
  
  .text {
    color: white;    
    padding: 16px 16px;
    border: 1px solid white;
  }

  .text:hover {
    color: var(--mantine-color-myColors-6);
    border: 1px solid var(--mantine-color-myColors-6);
    cursor: pointer;

  }
@media screen and (max-width: 768px) {

    
    .modalContainer {
        flex-direction: column !important;
        width: 100%;
    }

    .mediaContainer {
        width: 100% !important;
    }

    .tableContainer {
        width: 100% !important;
    }

}