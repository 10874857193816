/* Modal styles */
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;

}

.modal-content {
  border-radius: 10px;
  color: #eceff1;
  width: 600px;
  height: fit-content;
  display: flex;
  margin: auto;
  margin-top: 60px;
  flex-direction: column;
  z-index: 2;
}

.close {
  width: 100%;
  color: #eceff1;
  font-size: 24px;
  cursor: pointer;
  text-align: right;
}

.close:hover {
  color: #2196f3;
}

/* Videos Container */
.videosContainer {
  position: relative;
  display: flex;
  flex: 1;
  border-radius: 10px;
  overflow: hidden;
  z-index: 3;
  /* Ensure that the smaller video stays inside the container */
}

/* Friend Video */
.friendVideo {
  flex: 1;
  border-radius: 10px;
  transition: ease-in .4s;
  z-index: 4;
}

.overlay {
  position: absolute;
  background-color: black;
  width: 640px;
  height: 480px;
  height: fit-content;
  border-radius: 10px;
}

/* User Video */
.userVideo {
  position: absolute;
  top: 0px;
  right: 0;
  width: 20%;
  /* Adjust the width as needed */
  border-radius: 10px;
  transition: ease-in .4s;
  z-index: 5;
  border-top-right-radius: 0px;
}

.controls {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;

}

.upperControls {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  background-color: transparent;
}


.control {
  font-size: 36px;
  margin: 0px 30px;
}

.acceptControl,
.declineControl {
  font-size: 30px;
  margin: 20px 25px;
  padding: 5px;
  width: 50px;
  border-radius: 10px;
  background-color: green;
}

.declineControl {
  background-color: red;
}

.endcall {
  width: 50px;
  height: 50px;
  padding: 5px;
  background-color: red;
  border-radius: 10%;
  margin: 0px 30px;
}

.acceptControl:hover,
.declineControl:hover,
.control:hover {
  opacity: .5;
  cursor: pointer;
}

.endcall:hover {
  cursor: pointer;
  opacity: .5;

}

.hidden {
  display: none;
}

@media screen and (width < 768px) {

  .modal-content {
    margin: 0;
    margin: auto;
    margin-top: 60px;
    width: 100%;
  }

  .friendVideo {
    width: 90%;
  }
  
}