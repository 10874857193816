
.container {
    --progress: 0;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px;
}
h3 {
    width: 100%;
    text-align: center;
    font-size: 25px;
    margin: 0;
    padding-bottom: 20px;
}
.chart {
    float: right;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #32283d;
    background:  conic-gradient(#52228d 0deg, #adb3ba 0deg);
    transition: ease background 3s;
    font-size: 50px;

}
.chartContent {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#32283d;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 50%;
    font-size: 45px;

    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

