.wrapper {
    padding-bottom: calc(var(--mantine-spacing-xl) * 4);
  }
  
  .title {
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-weight: 900;
    margin-bottom: var(--mantine-spacing-md);
    text-align: center;
  
    @media (max-width: $mantine-breakpoint-sm) {
      font-size: rem(28px);
      text-align: left;
    }
  }
  
  .description {
    text-align: center;
  
    @media (max-width: $mantine-breakpoint-sm) {
      text-align: left;
    }
  }