.card {
    background-color: var(--mantine-color-body);
  }
  
  .avatar {
    width: 80px !important;
    border: rem(2px) solid var(--mantine-color-body);
  }

  .action:hover {
    color: var(--mantine-color-myColors-5);
    cursor: pointer;
  }