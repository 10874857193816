/*  html{
/*   background-color: #1B2545 !important;

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button, input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.NavbarShell {
  background-color: #141b31 !important;
}
  */

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    width: 100%;
    height: 100dvh;
    
  
  }
.AppShell {
  width: 100%;
  display: block;
}

.AppMain {
    display: flex;
    padding-top: 3em;
    margin-bottom: 0;
    height: 100%;
}

.AppMain main {
  width: calc(100% - 1em);
  padding-left: calc(16em);
  padding-top: 1em;
} 

button {
  background-color: var(--mantine-color-myColors-9) !important;
}

button:hover {
  opacity: .8;
}

.mantine-CloseButton-root {
  background-color: transparent !important;
}