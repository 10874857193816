
.outer {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
    min-height: calc(100dvh - 6em);

}
.grid {
    grid-template-columns: 10% 80% 10%;
    height: fit-content;
    justify-content: center;
}

.group {

    width: 80%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}


.control {
    font-size: 30px;
}

.ballGroup {
    display: flex;
    flex-direction: column;
}
.ballGroup:hover {
    color: #91a7ff;
    cursor: pointer;
}

.ball {
    font-size: 80px;
}

.ball:hover {
    color: #91a7ff;
    cursor: pointer;
}


.motionBtn {
    background-color:#2e2e2e;
    min-width: 5em !important;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #373737;
}

.motionBtn:hover {
    cursor: pointer;
    opacity: .7;
}

