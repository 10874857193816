
.container{
 
    animation-name: slideIn;
    animation-duration: 5s;
    animation-fill-mode: backwards;
}

.container .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #2d5ef0e1;
    padding: 15px;

}

.container .messsage {
    width: 100%;
    padding: 15px;
    background-color: #141b31;
    display: flex;
    justify-content: flex-start;
    font-size: 18px;
}

@keyframes slideIn {
    0% {top: -700px;}
    20% {top: 0px; }
    90% {top: 0px;}
    100% {top: -700px;}
}