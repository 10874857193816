.cardContainer {
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.card {
    min-width: 200px;
    max-width: 80%;
    margin-right: 10px;
}
.cashIcon {
    rotate: 90deg;
    font-size: 48px;
    margin: 0;
}
.plan {
    color: var(--mantine-color-myColors-6) !important;
    text-transform: uppercase;
    font-size: 30px !important;
    text-align: center;

}


.credit {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px !important;
}

.price {
    text-align: center;
    font-size: 30px !important;
    margin-bottom: 10px;
}

.cartItem {
    background-color: #2e2e2e;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {

    .cardContainer {
        flex-direction: column;
    }
    .card {
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
    }

}