.controlsContainer {
    display: flex !important;
    align-items: flex-end !important;
    justify-content: space-between !important;
    width: 100% !important;
    margin: 10px !important;
}

@media screen and (max-width: 768px) {

    .controlsContainer {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: flex-start !important;
    }

}