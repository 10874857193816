.root {
    padding-top: rem(80px);
    padding-bottom: rem(80px);
  }
  
  .label {
    text-align: center;
    font-weight: 900;
    font-size: rem(38px);
    line-height: 1;
    margin-bottom: calc(1.5 * var(--mantine-spacing-xl));
    color: var(--mantine-color-gray-2);
  
    @media (max-width: $mantine-breakpoint-sm) {
      font-size: rem(32px);
    }
  }
  
  .description {
    max-width: rem(500px);
    margin: auto;
    margin-top: var(--mantine-spacing-xl);
    margin-bottom: calc(1.5 * var(--mantine-spacing-xl));
  }
  
  .title {
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    text-align: center;
    font-weight: 900;
    font-size: rem(38px);
  
    @media (max-width: $mantine-breakpoint-sm) {
      font-size: rem(32px);
    }
  }