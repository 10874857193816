.container {
    display: flex;
    width: 100% !important;
    flex-direction: column;
    justify-content: space-between !important;
}

.connectionsBtn {
    width: 30% !important;
}

@media screen and (max-width: 768px) {

    .container {
        display: flex;
        flex-direction: column;
    }
    .connectionsBtn {
        width: 100% !important;
    }

}