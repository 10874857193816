/* Main Chat Container */
.chat {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #1b1b1b; /* Dark border color */
    border-radius: 10px;
    overflow: hidden;
    background-color: #1b1b1b; /* Dark background color */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    overflow-x: hidden !important;
    max-height: 88vh;
    min-height: 88vh;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  /* Chat Header */
  .chatHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color:#282626; /* Darker header background color */
    color: #eceff1; /* Light text color */
    border-bottom: 1px solid #37474f;
    border-radius: 10px 10px 0 0;
  }
  
  /* Friend Information */
  .friendInfo {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Friend Icon */
  .friendIcon, .videoIcon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .videoIcon:hover {
    color: #2196f3;
    cursor: pointer;
  }
  

  /* Friend Name */
  .friendName {
    font-size: 14px; /* Adjusted font size */
    font-weight: bold;
  }
  
  .chatContainer {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
  }
  .row{
    display: flex;
    width: 100%;
  }
/* Message Bubbles */
.friendBubble,
.userBubble {
  min-width: 50px;
  max-width: 50%;
  width: fit-content;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; /* Align items to the start vertically */
  border-radius: 15px;
  background-color: #37474f;
  word-break: break-word;
  font-size: 14px;
}

/* User Bubble */
.userBubble {
  margin-left: auto; /* Push user's messages to the right */
  background-color: #2196f3;
  color: #fff;
  justify-content: flex-end; /* Align user's messages to the end horizontally */
}

/* Message Content for User Bubble */
.userBubble .message {
  word-wrap: break-word;
}

/* Message Time for User Bubble */
.userBubble .time, .friendBubble .time {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 12px;
  opacity: .7;
}


  /* Send Container */
  .sendContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #37474f;
    border-radius: 0 0 10px 10px;
  }
  
  /* Textarea Styles */
  .sendContainer textarea {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #37474f;
    border-radius: 5px;
    resize: none;
    font-size: 14px;
    color: #eceff1;
    align-self: flex-start;
    background-color: #1b1b1b;
    height: fit-content !important;
  }
  
  .sendContainer textarea:focus {
    border: 1px solid #2196f3;
    outline: 1px solid #2196f3;
    outline-color: #2196f3;
  }
  /* Control Icon Styles */
  .controlIcon {
    font-size: 24px;
    cursor: pointer;
  }
  .controlIcon:hover {
    color: #2196f3;

  }
  
/* New class for EmojiPicker container */
/* New class for EmojiPicker container */
/* New class for EmojiPicker container */
.emojiPickerContainer {
    position: absolute;
    bottom:  100px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
  }
  
  /* Updated styles for EmojiPicker */
  .EmojiPickerReact {
    background-color: #242424 !important;
    
  }
  
  .EmojiPickerReact button {
    background-color: transparent !important;
  }
  /* Updated styles for EmojiPicker icon */
  .controlIcon {
    font-size: 30px;
    margin: 0px 30px;
  }
  
  .EmojiPickerReact input,
  .EmojiPickerReact h2 {
    background-color: #242424 !important;
  }


  @media screen and (max-width: 768px) {
    .modalContainer {
      width: 100%;
      flex-direction: column;
    }
    .mediaContainer {
      width: 100%;
    }
  }
  