.googleBtn {
    background-color: #2e2e2e !important;
    flex-grow: 1 !important;
    max-width: var(--group-child-width) !important;
    margin: 0 !important;
    padding: 0 !important;

    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    height: fit-content !important;
    width: fit-content !important;

    color: white !important;
}

.googleBtn span {
    margin: 0 !important;
    padding: 7px !important;
    width: 90%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: white !important;

}

.googleBtn div {
    background-color:  transparent !important;
    margin: auto !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content !important;
}
.googleBtn:hover, .googleBtn div:hover {
    background-color: var(--mantine-color-default-hover) !important;
    cursor: pointer;
}

.formContainer {
    display: flex;
}

@media screen and (max-width: 768px) {
    .formContainer {
        flex-direction: column;
        
    }

    form {
        max-height: 70vh;
        overflow-y: auto;
    }
}